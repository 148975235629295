import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  title: string
  tagLine: string
  colorPalette: ColorPalette
}

/** const */
const RibbonSection: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center pt-0 pb-3 text-white"
      style={{ borderBottom: "5px solid White", background: props.colorPalette.heroRibbon }}
    >
      <div className="ribbonContainer">
        <h3 className="ribbonTitle">{props.title}</h3>
        <h3 className="ribbonSubTitle">{props.tagLine}</h3>

        {/* <a
          className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow b-0"
          href="/apartments/"
          target="_blank"
          style={{ backgroundColor: "#ffffff", border: "0px", color: "#333333" }}
        >
          Explore Our Apartments
        </a> */}
      </div>
    </div>
  )
}

export default RibbonSection
